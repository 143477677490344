.dropdown {
  display: none;
  position: absolute;
  z-index: 9999;
  background-color: white;
  border: 1px solid rgba(29, 29, 29, 0.5);
  border-radius: 8px;
  padding: 16px;
  left: 0;
  bottom: 0;
  transform: translate(-100%, 100%);
  width: fit-content;
  &.open {
    display: flex;
    flex-direction: column;
  }
}

@font-face {
  font-family: "Avenir";
  src: url("../assets/fonts/Avenir-Roman.eot");
  src: url("../assets/fonts/Avenir-Roman.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Avenir-Roman.woff2") format("woff2"),
    url("../assets/fonts/Avenir-Roman.woff") format("woff"),
    url("../assets/fonts/Avenir-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

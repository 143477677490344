.button {
  background: #1d1d1d;
  border-radius: 100px;
  color: #f8f8f8;
  padding: 15px 26px;
  width: fit-content;
  outline: none;
  border: none;
  cursor: pointer;
}

.input {
  border: 1px solid rgba(29, 29, 29, 0.5);
  border-radius: 30px;
  height: 60px;
  width: 376px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  input {
    margin-left: 24px;
    flex-grow: 1;
    outline: none;
    border: none;
  }

  button.button {
    top: 0;
    right: 0;
    height: 100%;
    width: 101px;
  }

  &.invalid {
    border-color: red;
  }
}

textarea.input {
  border-radius: 100px;
  border-radius: 30px;
  padding: 18px 24px;
  height: 215px;
  resize: none;
}

input.input {
  padding: 18px 24px;
}

@import "mobile.scss";
@import "fonts.scss";
@import "reset.scss";
@import "colors.scss";
@import "button.scss";
@import "input.scss";
@import "dropdown.scss";

body {
  min-height: 100vh;
  display: block;
}
* {
  font-family: Avenir;
  box-sizing: border-box;
}

header {
  margin: 0 auto;
  width: 1440px;
  max-width: 100vw;
  padding: 27px 122px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .selected {
    font-weight: 800;
  }

  h1 {
    a {
      text-decoration: none;
    }
    font-family: Avenir;
    font-style: normal;
    font-size: 18px;
    font-weight: 900;
    :first-child {
      color: #33c3dc;
    }
    :nth-child(2) {
      color: #df4a6a;
    }
    :nth-child(3) {
      color: #62a181;
    }
  }

  .links {
    display: flex;
    .divider {
      width: 1px;
      height: 100%;
      background: black;
      margin: 0 23px;
    }
    a {
      color: black;
      text-decoration: none;
    }
    .menu {
      display: none;
    }

    @include mobile {
      & > a,
      .divider {
        display: none;
      }
      cursor: pointer;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 38px;
      height: 38px;
      border-radius: 19px;
      background-color: #1d1d1d;
      .menu {
        display: flex;
      }
    }
  }
  @include mobile {
    padding: 16px;
    align-items: center;
  }
}

main {
  max-width: 100%;
  #step-0 {
    h1 {
      font-weight: 800;
      font-size: 34px;
      text-align: center;
      @include mobile {
        font-size: 22px;
        line-height: 33px;
      }
    }
    .main-heading {
      margin: 62px auto 33px;
      line-height: 63px;
      @include mobile {
        line-height: 33px;
        margin: 30px auto 32px;
      }
    }
    .sub-heading {
      margin: 0 auto 47px;
      @include mobile {
        font-size: 28px;
        line-height: 36px;
        margin: 0 auto 40px;
      }
    }
    .info-text {
      font-size: 18px;
      line-height: 180%;
      text-align: center;
      color: #1d1d1d;
      margin-bottom: 34px;
      @include mobile {
        text-align: center;
      }
    }
    #start-button {
      margin-bottom: 83px;
    }

    .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #1d1d1d;
      margin-bottom: 118px;
      h1 {
        font-weight: 800;
        font-size: 34px;
        line-height: 46px;
        text-align: center;
        margin: 0 0 25px;
        @include mobile {
          font-size: 32px;
          line-height: 44px;
        }
      }
      .description-items {
        display: flex;
        width: 639px;
        max-width: 100%;
        justify-content: space-around;
        @include mobile {
          width: 100%;
          flex-direction: column;
        }
        .description-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          @include mobile {
            margin-bottom: 32px;
          }
          h2 {
            font-weight: 800;
            font-size: 24px;
            margin: 0 0 12px;
          }
          p {
            font-size: 18px;
            line-height: 31px;
          }
        }
      }
    }
  }

  #step-1,
  #step-2 {
    h1 {
      margin: 62px auto 19px;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
      color: #1d1d1d;
    }
    .tip {
      font-style: italic;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #1d1d1d;
      margin-bottom: 32px;
    }
    .dropzone {
      border: 1px dashed #000000;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 35px;
      min-width: 342px;
      cursor: pointer;
      margin-bottom: 137px;
      @include mobile {
        width: 100%;
        min-width: unset;
      }
      .file-icon {
        margin-bottom: 12px;
      }
      p {
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 6px;
      }

      .hover-overlay {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($color: #000, $alpha: 0.5);
        justify-content: center;
        align-items: center;
      }
      &.drag-hover {
        .hover-overlay {
          display: flex;
        }
      }
    }
  }

  #step-3 {
    h1 {
      margin: 62px auto 23px;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      color: #1d1d1d;
    }
    .tip {
      font-style: italic;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #000000;
      margin-bottom: 37px;
    }
    .input {
      margin-bottom: 222px;
      @include mobile {
        width: 100%;
        min-width: unset;
      }
    }
  }

  #step-4 {
    margin-bottom: 222px;
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      color: #1d1d1d;
      margin: 62px auto 23px;
    }
    p {
      font-style: italic;
      font-weight: normal;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #000000;
      margin-bottom: 37px;
    }

    & > * {
      display: none;
    }

    &.loading {
      .loading {
        display: unset;
      }
    }

    &.done {
      .done {
        display: unset;
      }
    }

    &.error {
      .error {
        display: unset;
      }
    }
  }

  section {
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: none;
    &.show {
      display: flex;
    }
    @include mobile {
      padding: 0 32px;
    }
  }

  .main-container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1336px;
    width: 100%;
    padding: 32px 36px;
    @include mobile {
      flex-direction: column;
    }

    .card-container {
      position: relative;
      .svg-star {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
      }
      img#card-image {
        object-fit: contain;
        width: 280px;
        height: 408px;
        position: absolute;
        top: 136px;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0px 38px 21px 10px rgba(233, 233, 233, 1);
      }
    }

    .card-info {
      margin-left: auto;
      align-self: center;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include mobile {
        margin-left: 0;
        align-items: center;
      }
      h1 {
        font-family: Avenir;
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 55px;
        color: #1d1d1d;
        margin-bottom: 62px;
        @include mobile {
          font-size: 32px;
          line-height: 44px;
          text-align: center;
        }
      }
      h2 {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 33px;
        color: #1d1d1d;
        margin-bottom: 20px;
        @include mobile {
          font-size: 18px;
          line-height: 25px;
        }
      }
      .share-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        @include mobile {
          flex-direction: column;
          align-items: center;
        }
        .share-item {
          display: flex;
          cursor: pointer;
          @include mobile {
            margin-bottom: 32px;
          }
          p {
            min-width: 110px;
            width: min-content;
            margin-bottom: 1px;
            margin-left: 12px;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #1d1d1d;
            @include mobile {
              width: 157px;
            }
          }
        }
      }
    }
  }

  .main-container.about {
    flex-direction: column;
    justify-content: center;
    margin: 120px auto;
    width: 100%;
    max-width: 910px;
    padding: 16px;
    p {
      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 43px;
      text-align: center;
    }

    @include mobile {
      p {
        font-size: 16px;
        line-height: 29px;
      }
    }
  }

  #contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 106px;
    margin-bottom: 83px;
    @include mobile {
      padding: 0 32px;
      margin-top: 0;
      margin-bottom: 0;
    }
    h1 {
      font-size: 16px;
      line-height: 180%;
      margin-bottom: 32px;
      text-align: center;
    }
    #contact-form {
      display: flex;
      flex-direction: column;
      .input-field {
        border: 1px solid rgba(29, 29, 29, 0.5);
        box-sizing: border-box;
        border-radius: 100px;
        margin-bottom: 16px;
        padding: 18px 24px;
        width: 376px;
        @include mobile {
          width: 311px;
        }
      }
      .message-field {
        height: 215px;
        border-radius: 30px;
      }
      #form-button {
        width: 376px;
        height: 60px;
        @include mobile {
          width: 311px;
        }
      }
    }
  }
}

footer {
  margin-top: auto;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  svg {
    @include mobile {
      max-height: 154px;
    }
  }
  img.mobile {
    display: none;
    width: 100%;
    object-fit: cover;
  }
  @include mobile {
    img.mobile {
      display: flex;
    }
    .desktop {
      display: none;
    }
  }
}
